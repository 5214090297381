import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { UserInterface } from '../../../interfaces/user.interface';
import { UserDataService } from '../../../services/user-data.service';
import { CommonService } from '../../../services/common.service';
import { Router } from '@angular/router';
import { AppState } from '../../../services/app.state';
import { environment } from '../../../environments/environment';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { FilterDialogComponent } from '../../shared/filter-dialog/filter-dialog.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { ProfileService } from '../../../services/profile.service';
import { VoiService } from 'src/app/services/voi-list';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-list-voi',
  templateUrl: './list-voi.component.html',
  styleUrls: ['./list-voi.component.css'],
})
export class ListVoiComponent implements OnInit {
  count_array: any = [];
  count: any = [];
  userData: UserInterface | undefined;
  displayedColumns: string[] = [
    'name',
    'voiid',
    'leadid',
    'insurancetype',
    'createddate',
    'voistartus',
    'emailstatus',
    'action',
  ];

  isLoading: boolean = true;
  dataSource: MatTableDataSource<any> | any = [];
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  page: any = { start: 0, limit: 10 };
  totalRecords = 0;
  isFilter: boolean = false;
  sortD: any = { sortField: 'name', sortOrder: 'desc' };

  customers: any = [];
  errorMessage: string = '';
  AssetsURL: string = environment.assetsURL;
  firebaseUsersCollection = '';
  getUsersDetailsPayLoad = {
    body: {
      start: 0,
      limit: 5,
      insurance_type: '',
      sortField: '',
      sortOrder: '',
    },
    header: {
      version: '1.0',
      created_by: '',
      request_id: '',
      message_name: 'lead_verification_admin_voi_R-All',
      message_type: 'Q',
      message_uuid: '',
      correlation_uuid: '',
      created_timestamp: '2020-01-01000000.000',
      service_completion_status_code: '',
    },
  };
  constructor(
    private afs: AngularFirestore,
    private router: Router,
    private commanService: CommonService,
    private voiServiceData: VoiService,
    private userDataService: UserDataService,
    public dialog: MatDialog,
    private appState: AppState,
    private datepipe: DatePipe
  ) {}

  ngOnInit() {
    this.isLoading = true;
    debugger
    this.firebaseUsersCollection = environment.firebaseUsersCollection;
    this.userData = this.userDataService.getUserData();
    this.userData != undefined ? this.userData.cognitoId : '';
    this.getUsersDetailsPayLoad.body.start = this.page.start;
    this.getUsersDetailsPayLoad.body.limit = this.page.limit;
    this.getAllUserList();
  }

  openChat(row: any) {
    this.router.navigate(['/chat']);
  }

  // ngAfterViewInit() {
  // this.dataSource.paginator = this.paginator;
  // this.dataSource.sort = this.sort;
  // }

  capitalizeFirstLetter(value: string): string {
    if (!value) {
      return ''; 
    }
    return value.charAt(0).toUpperCase() + value.slice(1);
  }

  sortedData(data:any) {
    return data.slice().sort((a, b) => b.created_date - a.created_date);
  }

  async getAllUserList() {
    this.isLoading = true;
    let createdId = this.userDataService.getLocalStorageValue('currentUser') || '';
    let data = JSON.parse(createdId);
    let currentSupportId = data[0].current_support_id || '';
    this.getUsersDetailsPayLoad.header.created_by = currentSupportId;
    let parseResponse: any = await this.voiServiceData.getVoiListData(
      this.getUsersDetailsPayLoad
    );    
        debugger
    if (parseResponse) {
      this.isLoading = false;
      const sorted= parseResponse?.data
      // const sorted= this.sortedData(parseResponse?.data)
      this.customers = sorted;      
      this.getMsgCount();
      this.totalRecords = parseResponse.total;
      this.dataSource = new MatTableDataSource(this.customers);
    } else {
      this.isLoading = false;
    }
  }


 
  sortData(sort: Sort) {
    this.sortD.sortField = sort.active;
    this.sortD.sortOrder = sort.direction;
    // this.sort.sortChange.emit(this.sortD);

    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

    this.getUsersDetailsPayLoad.body.start = this.page.start;
    this.getUsersDetailsPayLoad.body.limit = this.page.limit;

    this.getUsersDetailsPayLoad.body.sortField = this.sortD.sortField;
    this.getUsersDetailsPayLoad.body.sortOrder = this.sortD.sortOrder;
    this.getAllUserList();
  }
  pageChanged(event: any) {
    this.page.limit = event.pageSize;
    this.page.start = event.pageIndex * event.pageSize;
    this.getUsersDetailsPayLoad.body.start = this.page.start;
    this.getUsersDetailsPayLoad.body.limit = this.page.limit;
    this.getAllUserList();
  }
  clearFilter = () => {
    this.isFilter = false;
    this.getUsersDetailsPayLoad.body.start = 0;
    this.getUsersDetailsPayLoad.body.limit = 10;
    // this.getUsersDetailsPayLoad.body.name = '';
    // this.getUsersDetailsPayLoad.body.email = '';
    // this.getUsersDetailsPayLoad.body.phoneNumber = '';
    this.getAllUserList();
  };
  filterDialog = () => {
    const dialogRef = this.dialog.open(FilterDialogComponent, {
      width: '500px',
      data: {
        header: 'Search Customer',
        // name: this.getUsersDetailsPayLoad.body.name,
        // email: this.getUsersDetailsPayLoad.body.email,
        // phoneNumber: this.getUsersDetailsPayLoad.body.phoneNumber,
        type: 'customer',
      },
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result.action == 'Search') {
        // this.getUsersDetailsPayLoad.body.start = 0;
        // this.getUsersDetailsPayLoad.body.limit = 10;
        this.isFilter = true;
        // this.getUsersDetailsPayLoad.body.name = '';
        // this.getUsersDetailsPayLoad.body.email = '';
        // this.getUsersDetailsPayLoad.body.phoneNumber = '';
        let name = result.filterCustomer.name.trim();
        let email = result.filterCustomer.email.trim();
        let phoneNumber = result.filterCustomer.phoneNumber.trim();
        // if (name != '') this.getUsersDetailsPayLoad.body.name = name;
        // if (email != '') this.getUsersDetailsPayLoad.body.email = email;
        // if (phoneNumber != '')
        //   this.getUsersDetailsPayLoad.body.phoneNumber = phoneNumber;

        this.paginator.firstPage();
        this.getAllUserList();
      }
    });
  };
  showCustomerDetails(row: any) {
    this.appState.set('VOI', row);
    this.router.navigate([`/ivs-detail/${row.sequence_id}`]);
  }
  getMsgCount() {
    this.afs
      .collection(this.firebaseUsersCollection, (ref: any) =>
        ref
          .where(
            'current_support_id',
            '==',
            this.userData ? this.userData.cognitoId : ''
          )
          .limit(this.page.limit)
      )
      .snapshotChanges()
      .subscribe((snapshot: any) => {
        let user = [];
        user = snapshot.map((a: any) => {
          const data = a.payload.doc.data() as any;
          let ref = a;
          return { ref, ...data };
        });
        if (user.length > 0) {
          user.map((u: any) => {
            u.ref.payload.doc.ref
              .collection('support_chat')
              .orderBy('timestamp', 'asc')
              .onSnapshot((as: any) => {
                if (as.docs && as.docs.length > 0) {
                  this.count = [];
                  as.docs.filter((d: any, i: any) => {
                    let data = d.data();
                    if (data.from == 'user' && data.support_read == false) {
                      // this.count_array.length > 0 ? this.count_array.find((x :any )=>x.id == data.id) ?
                      // '': this.count_array.push(data):
                      this.count.push(data);
                    }

                    if (i == as.docs.length - 1) {
                      let c =
                        this.count == undefined ||
                        this.count == null ||
                        this.count.length == 0
                          ? ''
                          : this.count.length;

                      // if (this.customers.length > 0) {
                      this.customers.map((ca: any, ind: any) => {
                     if(ca.name && u.user_name){
                       if (ca.name.trim() == u.user_name.trim()) {
                         ca.unreadMsgCount = c;
                       }
                     }
                        if (ind == this.customers.length - 1) {
                          this.dataSource = new MatTableDataSource(
                            this.customers
                          );
                        }
                      });
                      // }
                    }
                  });
                }
              });
          });
        }
      });
  }
  getCreate(created: string) {
    let date = new Date(created);
    return this.datepipe.transform(date, 'MM-dd-yyyy');
  }
  // getDateTime(created:string) {
  //   let dateObj= new Date(created)
  //   return this.datepipe.transform(dateObj, 'medium');
  // }
}
