<div class=" login-page h-100">
    <div class="row h-100">
        <!-- <div class="col-lg-5 col-md-5 col-sm-5 login-part1"> -->
        <!-- <img src="{{AssetsURL}}/img/login_background.png" width="100%" alt="Login Logo"> -->
        <!-- </div> -->
        <div class="text-center p-200 col-lg-6 col-md-6 col-sm-6 h-100">
            <div class="yolohLogo mx-auto">
                <img [src]="AssetsURL+'/img/yoloh-logo.svg'" alt="">
            </div>
            <div class="andy"><img [src]="AssetsURL+'/img/thums.png'" alt=""></div>
        </div>
        <mat-card class="card col-lg-6 col-md-6 col-sm-6 bg-login p-2 h-100">
            <form [formGroup]="loginForm" class="form-container mt-4 pt-1">
                <h3>Login</h3>
                <img style="margin-left: 0;" src="{{AssetsURL}}/img/line.png" />

                <div class="mt-4 mb-3">
                    <mat-form-field appearance="outline">
                        <mat-label>Email</mat-label>
                        <input matInput formControlName="user_email" class=" form-control" placeholder="Email"
                            type="email" required>
                        <mat-error class="error" *ngIf="loginForm.controls['user_email'].errors?.required">
                            Please
                            enter the e-mail.</mat-error>
                        <mat-error class="error"
                            *ngIf="loginForm.controls['user_email'].errors?.pattern || loginForm.controls['user_email'].errors?.email">
                            Please
                            enter a valid e-mail.</mat-error>
                        <mat-error class="error" *ngIf="isNotValidEmail"> Please
                            enter a valid e-mail.</mat-error>
                    </mat-form-field>
                </div>

                <div class="mb-3 p-r d-block">
                    <mat-form-field appearance="outline">
                        <mat-label>Password</mat-label>
                        <input matInput #password class="  form-control" formControlName="user_password"
                            placeholder="password" (keyup.enter)="signIn()" autocomplete="off" type="password" required>
                        <!-- <span class="border-0 visibility-btn" mat-icon-button>
                                <mat-icon>lock</mat-icon>
                            </span> -->
                        <mat-error class="error" *ngIf="loginForm.controls['user_password'].errors?.required">
                            Please
                            enter the password.</mat-error>
                        <mat-error class="error" *ngIf="loginForm.controls['user_password'].errors?.pattern">
                            Please
                            enter a valid Password.</mat-error>
                        <mat-error class="error" *ngIf="incorrectPassword">Incorrect Password</mat-error>
                    </mat-form-field>
                    <!--                    <div class=" mb-4">-->
                    <!--                        <span class="forgot-pass p-a" style="align-items:end" routerLink="/forgot-password">-->
                    <!--                            Forgot Password ?-->
                    <!--                        </span>-->
                    <!--                    </div>-->
                </div>
                <!-- <div class="mb-3 p-r d-block">
                    <mat-form-field appearance="outline">
                        <mat-label>OTP</mat-label>
                        <input matInput #password class="  form-control" formControlName="otp"
                            placeholder="OTP" (keyup.enter)="signIn()" autocomplete="off" type="password" required>

                        <mat-error class="error" *ngIf="loginForm.controls['otp'].errors?.required">
                            Please enter the otp.</mat-error>

                        <mat-error class="error" *ngIf="incorrectOTP">Incorrect OTP</mat-error>
                    </mat-form-field>
                </div> -->
                <div>
                    <!-- w-100px (click)="signIn()" -->
                    <button class="btn-primary btn-lg rounded bg-yolow mb-3 " fxFlex="15%" type="submit"
                        [class.grey-btn]="(loginForm.status === 'INVALID')" (click)="signIn()"
                        [disabled]="(loginForm.status === 'INVALID') && isLoading" mat-button>
                        <span [ngStyle]="{'display':!isLoading ? 'block': 'none'}">LOGIN</span>
                        <span [ngStyle]="{'display': isLoading ? 'block': 'none'}">
                            <mat-spinner style="margin:5px auto;" [diameter]="25">
                            </mat-spinner>
                        </span>
                    </button>

                </div>
                <div>

                    <!-- <div fxLayout fxLayout="row" fxLayout.xs="column" fxLayout.sm="column" fxFlex.sm="55%" fxFlex.xs="55%" fxLayoutGap="20px" >
                        <button class="btn btn-primary btn-lg rounded bg-yolow" type="submit" mat-button
                            (click)="typeChange('Agent')">
                            Sign up As Agent
                        </button>
                        <button class="btn btn-primary btn-lg rounded bg-black" type="submit" mat-button
                            (click)="typeChange('Vender')">
                            Sign up As Vender
                        </button>
                    </div> -->
                </div>
            </form>
        </mat-card>
    </div>
</div>