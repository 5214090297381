import React, { useState, useRef, useEffect } from "react";
import {
  collection,
  query,
  where,
  getDocs,
  setDoc,
  doc,
  limit,
  updateDoc,
  serverTimestamp,
  getDoc,
  onSnapshot,
  orderBy,
} from "firebase/firestore";
import { db } from "../firebase";
import Badge from "react-bootstrap/Badge";
import {styles} from "../Custom";

const UnreadCount = (props: any) => {
  const userId = props.userid;
  const counter = useRef<any>(null);
  const [unreadCount, SetUnreacCount] = useState();
  useEffect(() => {
    //counter.current = 6;
    let total = 0;
    const pendingCountQry = collection(
      db,
      "prod_users",
      `${userId}`,
      "support_chat"
    );
    const qry = query(pendingCountQry, where("support_read", "==", false));
    const pendingUserCounter: any = [];
    const unsub = onSnapshot(qry, (querySnapshot) => {
      total = querySnapshot.size;
      counter.current = total;

    });
  }, []);

  return (
    <>
      {counter.current !== 0 ? (
        <span className="my-unread-counter" style={{
          display: 'inline-block',
          padding: '5px 8px',
          fontSize: '14px',
          fontWeight: '700',
          lineHeight: '1',
          textAlign: 'center',
          color:"#fff",
          whiteSpace: 'nowrap',
          verticalAlign: 'baseline',
          borderRadius: '50%',
          position: "absolute",
          backgroundColor: '#09143a',
          bottom: '8px',
          right: '10px'
          }}>
          {counter.current}
        </span>
      ) : (
        ""
      )}
    </>
  );
};

export default UnreadCount;
