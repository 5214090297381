<mat-toolbar class="toolbar">
    <mat-toolbar-row>
        <button mat-icon-button class="menu" [disableRipple]="true" (click)="toggle()" fxShow="true" fxHide.gt-sm>
            <mat-icon>menu</mat-icon>
        </button>
        <a class="navbar-brand" routerLink='/'>
            <img src="./assets/img/yoloh-logo.png" width="160" class="d-inline-block align-top" alt="Simba Logo Img">
        </a>
        <span class="menu-spacer"></span>
        <div fxShow="true" fxHide.lt-md="true">
            <!-- The following menu items will be hidden on both SM and XS screen sizes -->
            <span class="text-center" *ngFor="let menuItem of menuItems">
                <span *ngIf="menuItem.title!='Logout'; else NotLogout">
                    <a mat-button [disableRipple]="true" routerLinkActive="active-list-item"
                        [routerLink]="[menuItem.path]">
                        <p class="title">{{ menuItem.title }}</p>
                    </a>
                </span>
                <ng-template #NotLogout>
                    <a mat-button [disableRipple]="true" routerLinkActive="active-list-item" (click)="signOut()">
                        <p class="title">{{ menuItem.title }}
                            <i [class]="menuItem.icon" data-pack="default" data-tags="sign in"></i>
                        </p>
                    </a>
                </ng-template>
            </span>
            <a mat-button [disableRipple]="true">
                <span class="mat-list-base" routerLinkActive="active-list-item" routerLink="/chat"
                    (click)="onChatClick()">
                    <!-- matBadge="{{notificationCount}}" matBadgeColor="primary"  matBadgeSize="medium" -->
                    <mat-icon class="material-icons-outlined" matBadge="{{unreadMsgCount}}" matBadgeColor="primary"
                        matBadgeSize="medium">forum</mat-icon> <!-- chat -->
                </span>
            </a>
            <a mat-button [disableRipple]="true">
                <span class="mat-list-base" routerLinkActive="active-list-item" #ddTrigger="matMenuTrigger"
                    [matMenuTriggerFor]="dd">
                    <mat-icon matBadge="{{notificationCount}}" matBadgeColor="warn">notifications_none</mat-icon>
                    <!-- <mat-icon matBadge="{{notificationCount}}" matBadgeColor="warn">inbox</mat-icon> -->
                </span>
            </a>
            <span class="navbar" mat-button placement="bottom right">
                <span class="nav-item dropdown">
                    <a class="nav-link dropdown-toggle " href="#" role="button" data-toggle="dropdown"
                        aria-haspopup="true" aria-expanded="false">
                        <img [src]="profile_image" width="40" height="40" class="rounded-circle mr-2">
                        <span class="fs-16 font-family-NSB">{{ userData != null && userData != undefined ?
                            userData.email : ''}}</span>
                    </a>
                    <div class="dropdown-menu" id="navbarDropdownMenuLink" aria-labelledby="navbarDropdownMenuLink">
                        <a class="dropdown-item" href="#" (click)="signOut()">Log Out</a>
                    </div>
                </span>
            </span>

        </div>
    </mat-toolbar-row>
</mat-toolbar>
<mat-menu #dd="matMenu" [overlapTrigger]="false" style="max-width: unset;" class="notification_main">
    <div *ngIf="notifications && notifications.length > 0; else NoNotifications">
        <div class="notification-content">
            <div class="readALlText" style="border-bottom: 1px solid #f5f5f5;" (click)="readAllNotification()">Read All
            </div>
            <div class="dropdown" *ngFor="let notif of notifications">
                <mat-card class="noti-card" [ngClass]="notif.is_read ? 'unRead-card':''"
                    style="box-shadow: unset; margin: 0px; border-bottom: 1px solid #f5f5f5;"
                    [ngStyle]="{ 'background-color' : (notif.is_read)? '#fff' : '#f8f8ff' }">
                    <div (click)="clickNotification(notif)" style="display: flex; gap: 10px;">
                        <div style="display: block; position: relative;">
                            <!-- <div
                                style="height: 40px; width: 40px; border-radius: 100px; background-color: #f5f5f5; display: flex; justify-content: center; align-items: center;"> -->
                            <img src="{{getIconUrl(notif)}}" alt="Type Icon" width="40" height="40"
                                style="border-radius: 100px; " />
                            <!-- </div> -->
                            <div *ngIf="!notif.is_read">
                                <div class="notification_Dot"></div>
                            </div>
                        </div>
                        <div style="position: relative;">
                            <div style="font-size: 16px; font-family: NB;">
                                <strong>{{getNotificationTitle(notif)}},</strong>
                            </div>
                            <div style="font-size: 14px;">{{getNotificationText(notif)}}</div>
                        </div>
                    </div>
                    <div style="font-size: 14px; position: absolute; right: 0; bottom: 0; margin:3px;">
                        {{notif?.created_date | timeAgo}}</div>
                </mat-card>
            </div>
            <!-- readAllNotification -->
            <div class="viewMore" (click)="ViewMoreNotification()">View More</div>
        </div>
    </div>
    <ng-template #NoNotifications>
        <div class="notification-content mt-3">
            <div class="no-notifications-dropdown">
                <span>You're All Caught Up!</span>
            </div>
        </div>
    </ng-template>
</mat-menu>